import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonLabel,
} from '@ionic/react';
import { TopBar, Button, Icon, Snackbar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { BackButton } from '../../../../components/Base/BackButton';
import { useLocationState } from '../../../../hooks/useLocationState';
import { EPermissionsIds } from '../../../../utils/constants';
import styles from './styles.module.scss';

const NotAvailablePlace: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const state = useLocationState() as any;
  const selectedDates = state?.selectedDates;
  const notAssignedParking = state?.notAssignedParking;
  const header = state?.header;

  const gotoHowToGet = () => {
    history.push(`/services/${EPermissionsIds.HOW_TO_GET}`);
  };

  const handleOnclickBackbutton = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={<BackButton onClick={handleOnclickBackbutton} />}
          title={
            notAssignedParking
              ? t('reservation_planned')
              : t('reservation_last_minute')
          }
        />
      </IonHeader>
      <IonContent fullscreen>
        {selectedDates && (
          <Snackbar
            type="info"
            text={selectedDates
              .map(e => `${dayjs(e).utc(false).format('DD MMM')}`)
              .join(', ')}
            align="center"
          />
        )}
        <div className={styles.center}>
          <IonLabel className={styles.title_2}>{header}</IonLabel>
          <div className={styles.center_subtext}>
            <IonLabel className={styles.subhead}>
              {t('not_available_parking_sub')}
            </IonLabel>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={() => gotoHowToGet()}>
              <Icon className="icon icon-campus" />
              {t('lbl_more_how_to_get')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default NotAvailablePlace;

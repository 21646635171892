import { Http, HttpResponse, HttpOptions } from '@capacitor-community/http';
import { isEmpty } from '../../utils/functions';
import { tokenServices } from '../services/token/token.services';
import { APIRequestError } from './APIRequestError';
// import HTTPCacheService from './httpCache';
import { isPlatform } from '@ionic/react';
import { CapacitorHttp } from '@capacitor/core';
const HTTPServer = () => {
  if (isPlatform('android')) {
    return CapacitorHttp;
  } else {
    return Http;
  }
};

const urlsNotSedeHeader = [
  'v1/lottery/isActive',
  '/v1/parking/permanency/released',
];

let store;

export const injectStore = _store => {
  store = _store;
};

let responseInterceptorSuccessFunction: (
  response: HttpResponse,
) => Promise<any>;
let responseInterceptorErrorFunction: (
  error: any,
  originalConfig: {
    headers?: any;
    params?: any;
    url?: string;
    data?: any;
    method?: string;
    _retry: boolean;
  },
) => Promise<any>;
export interface muleSoftConfig {
  headers?: any;
  params?: any;
  baseUrl?: string;
  data?: any;
  responseType?: string;
}
const httpCommonRequestCall = async (config: any, isFinalUrl = false) => {
  let finalUrl = isFinalUrl
    ? config.url
    : `${process.env.REACT_APP_API_URL}${config.url}`;
  if (config.params) {
    Object.keys(config.params).forEach(key => {
      if (config.params[key] == null) {
        delete config.params[key];
      }
    });
  }

  const encodeGetParams = p =>
    Object.entries(p)
      .map(kv => kv.map(encodeURIComponent).join('='))
      .join('&');
  if (config.params) {
    finalUrl += '?' + encodeGetParams(config.params);
    delete config.params;
  }
  if (!config.headers) {
    config.headers = {};
  }
  if (!config.headers['content-type']) {
    config.headers['content-type'] = 'application/json';
  }
  Object.keys(config.headers).forEach(headerKey => {
    const headerValue = config.headers[headerKey] ?? '';
    config.headers[headerKey] = headerValue;
  });

  config.url = finalUrl;
  // const response = await HTTPCacheService.cacheCall(
  //   config as HttpOptions,
  //   makeRequestAndSaveResponse,
  // );
  const response = await makeRequestAndSaveResponse(config as HttpOptions);
  return response;
};
const makeRequestAndSaveResponse = async config => {
  if (config.data) {
    Object.keys(config.data).forEach(key => {
      if (config.data[key] == null) {
        delete config.data[key];
      }
    });
  }

  if (config.params) {
    Object.keys(config.params).forEach(key => {
      if (config.params[key] == null) {
        delete config.params[key];
      }
    });
  }

  await tokenInterceptors(config.headers, config.url);
  let returnValue;
  try {
    const response = await HTTPServer().request(config);
    if (
      response.status === 200 &&
      config.responseType == 'blob' &&
      !isEmpty(response.data) &&
      response.data !== 'data:'
    ) {
      const contentType = response.headers['content-type'];
      const data = await (
        await fetch(`data:${contentType};base64,${response.data}`)
      ).blob();
      response.data = data;
    }
    const hasError = response.status > 299;
    if (hasError) {
      if (responseInterceptorErrorFunction) {
        returnValue = await responseInterceptorErrorFunction(response, {
          ...config,
          _retry: false,
        });
      } else {
        returnValue = response;
      }
    } else if (responseInterceptorSuccessFunction) {
      returnValue = await responseInterceptorSuccessFunction(response);
    } else {
      returnValue = response;
    }
  } catch (error) {
    if (responseInterceptorErrorFunction) {
      throw new APIRequestError(error);
    } else {
      returnValue = error;
    }
  }
  return returnValue;
};
const muleSoftInstance = {
  call: (config: any) => {
    return httpCommonRequestCall(config, true);
  },
  interceptors: {
    response: {
      use: (
        successFunction: (res: HttpResponse) => Promise<any>,
        errorFunction: (
          error: any,
          originalConfig: {
            headers?: any;
            params?: any;
            url?: string;
            data?: any;
            method?: string;
            _retry: boolean;
          },
        ) => Promise<any>,
      ) => {
        responseInterceptorSuccessFunction = successFunction;
        responseInterceptorErrorFunction = errorFunction;
      },
    },
  },
  get: async (
    url: string,
    config?: muleSoftConfig,
    isFinalUrl: boolean = false,
  ): Promise<HttpResponse> => {
    const httpConfig = {
      method: 'get',
      ...config,
      url: config ? (config.baseUrl ? config.baseUrl : url) : url,
    };
    return httpCommonRequestCall(httpConfig, isFinalUrl);
  },
  post: async (
    url: string,
    data?: any,
    config?: muleSoftConfig,
    isFinalUrl: boolean = false,
  ): Promise<HttpResponse> => {
    const httpConfig = {
      method: 'post',
      data: data ? data : config.data,
      ...config,
      url: config ? (config.baseUrl ? config.baseUrl : url) : url,
    };
    return httpCommonRequestCall(httpConfig, isFinalUrl);
  },
  delete: async (
    url: string,
    config?: muleSoftConfig,
  ): Promise<HttpResponse> => {
    const httpConfig = {
      method: 'delete',
      ...config,
      url: config ? (config.baseUrl ? config.baseUrl : url) : url,
    };
    return httpCommonRequestCall(httpConfig);
  },
  put: async (
    url: string,
    data?: any,
    config?: muleSoftConfig,
    isFinalUrl: boolean = false,
  ): Promise<HttpResponse> => {
    const httpConfig = {
      method: 'put',
      data: data ? data : config.data,
      ...config,
      url: config ? (config.baseUrl ? config.baseUrl : url) : url,
    };
    return httpCommonRequestCall(httpConfig, isFinalUrl);
  },
  patch: async (
    url: string,
    data?: any,
    config?: muleSoftConfig,
  ): Promise<HttpResponse> => {
    const httpConfig = {
      method: 'patch',
      data: data ? data : config.data,
      ...config,
      url: config ? (config.baseUrl ? config.baseUrl : url) : url,
    };
    return httpCommonRequestCall(httpConfig);
  },
};

const tokenInterceptors = async (headers: any, url: string) => {
  const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
  const tokenRedux = store.getState().auth.idToken;

  const token = tokenRedux ?? (await tokenServices.getLocalIdToken()) ?? null;

  if (token && isApiUrl) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (!urlsNotSedeHeader.some(data => url.includes(data))) {
    const sedeEmployee = store.getState().user.user.defaultSede;

    if (sedeEmployee?.id) {
      headers.sedeEmployee = sedeEmployee.id.toString();
    }
  }
};

export default muleSoftInstance;
